/* eslint-disable global-require */
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import pages from "@/router/routes/pages";
import others from "@/router/routes/others";
export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    others,
    pages,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login_page.png"),
      logoImg: require("@/assets/images/pages/Poultrac_logo.png"),
      // validation rules
      required,
      email,
      rowData: [],
      // loginImage: {
      //   backgroundImage: require("@/assets/images/login/login.png"),
      // },
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/login/login-left.png");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then((success) => {
        if (success) {
          useJwt
            .login({
              email: this.userEmail,
              password: this.password,
            })
            .then((response) => {
              useJwt.setToken(response.data.data.token);
              const userData = response.data.data.user;
              const role = response.data.data.user.roles;
              localStorage.setItem("user-role", role);
              userData.role = role;
              localStorage.setItem("userData", JSON.stringify(userData));
              window.location = "/";
              useJwt.setRefreshToken(response.data.data.token);
              this.$router
                .replace(getHomeRouteForLoggedInUser(role))
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: `Welcome ${userData.name || userData.username}`,
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: `You have successfully logged in as ${role}. Now you can start to explore!`,
                    },
                  });
                });
            })
            .catch((error) => {
              const data = error.response.data.message;
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  // title: `Welcome `,
                  icon: "CoffeeIcon",
                  variant: "danger",
                  text: "Email password did not match",
                },
              });
            });
        }
      });
    },
    privacy() {
      this.$router.push({
        path: "/privacy",
      });
    },
  },
};
